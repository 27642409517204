import { lighten } from 'polished'
import styled from 'styled-components'
import {
  Prompt,
  Flex,
  H4,
  H3,
  H1,
  Button,
  SectionTitle as CommonSectionTitle,
  Wrapper,
} from '@renderbus/common/components'
import { color, hideSMDown, Media, typography, spacing, hideSMUp } from '@renderbus/common/theme'
import Decoration from '../images/2020-1111/decoration.png'

export const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  min-height: 720px;
  z-index: 0;
  text-align: center;
  ${H1} {
    font-size: 60px;
    text-shadow: rgba(0, 0, 0, 0.75) 3px 0 3px;
  }
  ${Media.lessThan(Media.small)} {
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`
export const DesktopOnlyPrompt = styled(Prompt)`
  ${hideSMDown};
`

export const PlatformCard = styled(Flex)`
  flex-direction: column;
  text-align: center;
  ${H4} {
    margin: 3em 0 2em;
  }
  > svg {
    width: 7.5rem;
    height: 7.5rem;
  }
  ${Media.lessThan(Media.small)} {
    > svg {
      width: 4rem;
      height: 4rem;
    }
    ${H4} {
      margin: 1em 0;
    }
  }
`
export const SoftwareBG = styled(Flex)`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: ${lighten(0.07, color.panel)};
  ${Media.lessThan(Media.small)} {
    width: 45px;
    height: 45px;
    > img {
      transform: scale(0.5);
    }
  }
`
export const WorldMapContainer = styled.div`
  position: absolute;
  top: 145px;
  left: 0;
  right: 0;
  bottom: 30px;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center center;
  }
  ${Media.lessThan(Media.small)} {
    top: 67px;
    bottom: 75px;
    img {
      object-fit: cover;
    }
  }
`

export const BannerAside = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  height: 160px;
  width: 100%;
  color: white;
  ${Flex} {
    ${Flex} {
      width: 32%;
      flex-wrap: wrap;
    }
  }
  p {
    margin: 0;
    line-height: 1.5;
  }
  svg {
    width: 75px;
    height: 75px;
    margin-right: 2em;
  }
  ${H3} {
    br {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    ${H3} {
      font-size: ${typography.h4};
      br {
        display: block;
      }
    }
    svg {
      width: 50%;
      height: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1em;
    }
    p {
      display: none;
    }
  }
`
export const VerticalDivider = styled.div`
  width: 1px;
  height: 75%;
  margin: 0;
  flex-shrink: 0;
  background-color: ${color.divider};
  ${hideSMDown};
`
export const AdCopy = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  margin-left: 7rem;
  height: 90%;
  ${Media.lessThan(Media.small)} {
    height: 80%;
    padding-top: 50%;
    margin-left: 1rem;
  }
`
export const AdSubTitle = styled.p`
  color: white;
  text-align: left;
  font-size: 1.75rem;
  margin-top: ${spacing.small};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`
export const GoCloudImg = styled.img`
  display: block;
  margin: auto;
  width: 48%;
  padding-top: 40px;
  + button {
    position: absolute;
    top: 54%;
    left: 45%;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding-top: 30%;
    padding-right: 10%;
    + button {
      position: absolute;
      top: 54%;
      left: 36%;
    }
  }
`
export const HalfPriceImg = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;
  padding-top: 90px;
  ${Media.lessThan(Media.small)} {
    padding-top: 120px;
  }
`
export const SoftwareTips = styled.div`
  color: ${color.prompt};
  font-size: ${typography.textSmall};
  text-align: center;
  width: 100%;
  margin: 0 !important;
  ${Media.lessThan(Media.small)} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const Line = styled.span`
  width: 60px;
  height: 4px;
  margin: ${spacing.small} 0;
  background-color: white;
`
export const LineOnlyWeb = styled(Line)`
  ${hideSMDown}
`
export const QingjiaoTitle = styled.p`
  color: white;
  font-size: 86px;
  padding: ${spacing.large} 0 0;
  margin: 0;
  ${Media.lessThan(Media.small)} {
    font-size: 34px;
  }
`
export const QingjiaoSubTitle = styled.p`
  color: white;
  font-size: 34px;
  padding: 0;
  margin: 0 0 ${spacing.small};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h3};
  }
`
export const QingjiaoText = styled.p`
  color: white;
  font-size: 20px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
  }
`
export const Bold = styled.span`
  font-weight: bold;
`
export const Triangle = styled.div`
  :before {
    content: '▼';
    display: block;
    font-size: 18px;
    color: white;
    padding-bottom: ${spacing.small};
  }
`
export const QyTitle = styled.img`
  margin-bottom: ${spacing.small};
  ${Media.lessThan(Media.small)} {
    max-width: 90%;
    margin-bottom: ${spacing.base};
  }
`
export const QyDetail = styled.p`
  font-size: ${typography.h4};
  margin: 0 0;
  img {
    margin: 0 0;
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.base};
    img {
      max-width: 40%;
    }
  }
`
export const MbShow = styled.div`
  ${hideSMUp};
`
export const MbHidden = styled.div`
  ${hideSMDown};
`
export const RechargeContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-left: 7rem;
  ${Media.lessThan(Media.small)} {
    margin: 0;
    align-items: center;
  }
`
export const RechargeTitle = styled.div`
  text-align: center;
  font-size: 1.875rem;
  font-weight: 500;
  color: white;
  margin-top: 12.5rem;
  text-shadow: 0px 2px 2px rgba(145, 34, 32, 0.4);
  ${Media.lessThan(Media.small)} {
    margin-top: 1rem;
    font-size: ${typography.h4};
  }
`
export const RechargeBtn = styled(Button)`
  margin-top: 2.25rem;
  font-weight: 400;
  height: 3.125rem;
  border-radius: 1.5rem;
  font-size: ${typography.h3};
  background: #13cb75;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textSmall};
    margin-top: 1.375rem;
    height: 1.875rem;
    line-height: 1.875rem;
  }
`
export const TimeLimit = styled.div`
  font-size: ${typography.h3};
  color: white;
  margin-top: 1.75rem;
  font-weight: 300;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textSmall};
    margin-top: 1.5rem;
  }
`
export const Comments = styled.div`
  font-size: 12px;
  color: #666666;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -80px;
  margin: auto;
  ${Media.lessThan(Media.small)} {
    bottom: -30px;
    padding: 0 10px;
  }
`

export const MemberRechargeBtn = styled(Button)`
  margin-top: 2.25rem;
  font-weight: 400;
  height: 3.125rem;
  border-radius: 1.5rem;
  font-size: ${typography.h3};
  background: rgba(255, 255, 255, 1);
  color: ${color.primary};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textSmall};
    height: 2.5rem;
    line-height: 2.5rem;
  }
`
export const ChuanShuTitle = styled.div`
  ${H1} {
    font-size: 60px;
    text-shadow: 1px 3px 4px rgba(0, 0, 0, 0.6);
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 240px;
    ${H1} {
      font-size: 20px;
    }
  }
`
export const SubTitle = styled.p`
  background-image: linear-gradient(to right, #f3dd38, #e2f95a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  position: relative;
  margin: -100px auto ${spacing.large} auto;
  :before {
    content: '';
    background: url(${Decoration});
    background-size: 40px 40px;
    position: absolute;
    height: 40px;
    width: 40px;
    left: -50px;
    top: ${spacing.small};
    ${Media.lessThan(Media.small)} {
      background-size: ${spacing.large};
      height: ${spacing.large};
      width: ${spacing.large};
      left: -40px;
      top: 0;
    }
  }
  :after {
    content: '';
    background: url(${Decoration});
    background-size: 40px 40px;
    position: absolute;
    height: 40px;
    width: 40px;
    right: -50px;
    top: 10px;
    ${Media.lessThan(Media.small)} {
      background-size: ${spacing.large};
      height: ${spacing.large};
      width: ${spacing.large};
      right: -40px;
      top: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h3};
  }
`
export const ActivityTime = styled.p`
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #18ecff, #23ff98);
  border-image-slice: 10;
  background-image: linear-gradient(to right, #18ecff, #23ff98);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  padding: ${spacing.small} ${spacing.base};
  margin-top: ${spacing.large};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h3};
  }
`
export const Double11Img = styled.img`
  ${Media.lessThan(Media.small)} {
    width: 95%;
  }
`
export const Double11Button = styled(Button)`
  width: 200px;
  height: 50px;
  line-height: 50px;
  border-radius: 1.5rem;
  ${Media.lessThan(Media.small)} {
    width: 160px;
    height: 40px;
    line-height: 40px;
    border-radius: 1.5rem;
    margin-top: ${spacing.base};
    font-size: ${typography.h3};
  }
`
export const QingyunImg = styled.img`
  margin-bottom: ${spacing.base};
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-bottom: 50px;
  }
`

export const SoftwareListContainer = styled(Flex)`
  width: 1290px;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0 auto;
  > div {
    margin: 0 60px 70px 0;
    &:nth-child(9),
    &:nth-child(18) {
      margin-right: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: unset;
    > div {
      margin: 0 0 25px;
    }
    justify-content: flex-start;
  }
`

export const SoftwareItem = styled(Flex)`
  max-width: 90px;
  overflow: visible;
  flex-direction: column;
  margin: 1em 0;
  white-space: nowrap;
  p {
    margin: 20px 0 0;
  }
  ${Media.lessThan(Media.small)} {
    width: 33%;
    max-width: unset;
    p {
      margin: 10px 0 0;
    }
  }
`

export const SectionTitle = styled(CommonSectionTitle)`
  font-size: 40px;
  font-weight: bold;
`

export const BannerWrapper = styled(Wrapper)`
  height: 100%;
  > ${Flex} {
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    display: flex;
    align-items: center;
    > ${Flex} {
      align-items: flex-start;
      height: unset;
    }
    ${H3} {
      text-align: center;
      white-space: pre-line;
      font-weight: 400;
    }
  }
`

export const OverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  ${Media.lessThan(Media.small)} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const Envelope = styled.div`
  display: ${p => (p.isShowEnvelope ? 'flex' : 'none')};
  position: ${p => (p.isShowEnvelope ? 'fixed' : 'none')};
  bottom: 50px;
  left: 30px;
  justify-content: center;
  pointer-events: auto;
  button {
    position: absolute;
    font-size: 16px;
    bottom: 20px;
    width: 120px;
    height: 36px;
    background: #2a45a0;
    border-radius: 18px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      box-shadow: 0px 6px 9px 1px rgba(42, 69, 160, 0.3);
    }
  }
  ${Media.lessThan(Media.small)} {
    height: unset;
    bottom: 52vw;
    left: 7.73vw;
    width: 151px;
    height: 101px;
    button {
      width: 75px;
      height: 22px;
      bottom: 13px;
      font-size: 10px;
    }
  }
`

export const StatementDialog = styled.div`
  display: ${p => (p.isShow ? 'block' : 'none')};
  position: fixed;
  bottom: 50px;
  left: 30px;
  width: 760px;
  height: 520px;
  background: rgba(42, 69, 160, 0.9);
  border-radius: 10px;
  color: #ffffff;
  padding: 40px 40px 35px 29px;
  pointer-events: auto;
  .close-img {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .close-img-hover {
    display: none;
  }
  &:hover {
    box-shadow: 0px 6px 9px 1px rgba(42, 69, 160, 0.3);
    .close-img-hover {
      display: block;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .close-img {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 90vw;
    height: unset;
    bottom: unset;
    left: unset;
    padding: 20px 20px 15px 15px;
    .close-img {
      width: 30px;
      height: 30px;
    }
    &:hover {
      .close-img {
        display: block;
      }
      .close-img-hover {
        display: none;
      }
    }
  }
`

export const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    font-size: 30px;
  }
`

export const StatementContent = styled.div`
  display: flex;
  gap: 20px;
  img {
    width: 189px;
    height: 263px;
    margin-top: 15px;
  }
  .first-text-mb {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    display: block;
    font-size: 12px;
    img {
      width: 100px;
      height: 130px;
      margin-top: 10px;
    }
    .top-content {
      display: flex;
    }
    .first-text-mb {
      display: block;
      text-indent: 2em;
      margin-left: 20px;
    }
  }
`

export const TextContent = styled.div`
  font-size: 14px;
  .bottom-text {
    text-align: right;
  }
  p {
    text-indent: 2em;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    .first-text {
      display: none;
    }
  }
`
export const ReceiptBig = styled.div`
  display: ${p => (p.showBig ? 'block' : 'none')};
  position: ${p => (p.showBig ? 'fixed' : 'unset')};
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    position: fixed;
    width: 600px;
    height: 800px;
    display: flex;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: 80vw;
      height: auto;
    }
  }
`
export const SpringNotice = styled.div`
  position: fixed;
  top: 100px;
`
