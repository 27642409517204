import React from 'react'
import { Wrapper, Button } from '@renderbus/common/components'

import styled from 'styled-components'
import { color, mbSize } from '@renderbus/common/theme'
import { baseLink } from '@renderbus/common/service'

const BannerContainer = styled.div`
  padding: 40px 0;
  text-align: center;
  background: ${color.primary};
  color: white;
  @media (max-width: 600px) {
    padding: ${mbSize(44)} 0 ${mbSize(60)} 0;
  }
`
const BannerTitle = styled.h2`
  margin: 1rem;
  font-size: 2.25rem;
  font-weight: 500;
  @media (max-width: 600px) {
    span {
      font-size: 22px;
      display: block;
    }
    .comma {
      display: none;
    }
  }
`
const BannerSubTitle = styled.p`
  margin: 0 0 1.5rem;
`
const CouponButton = styled.a`
  width: 160px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-size: 16px;
  color: #13cb75;
  display: block;
  line-height: 40px;
  margin: 0 auto;
  &:hover {
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 600px) {
    width: ${mbSize(240)};
    height: 30px;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-size: 12px;
    line-height: 32px;
  }
`

class RegisterBanner extends React.PureComponent {
  render() {
    const { isPicture, isNoLimit } = this.props
    return (
      <BannerContainer>
        <Wrapper>
          {isNoLimit && (
            <>
              <BannerTitle>新用户注册，免费领取无门槛云渲染券</BannerTitle>
              <BannerSubTitle>畅享专业可靠的高速云渲染服务</BannerSubTitle>
            </>
          )}
          {!isNoLimit && (
            <>
              <BannerTitle>
                {isPicture ? (
                  <>
                    <span>效果图新用户注册</span>
                    <span className='comma'>，</span>
                    <span>即领超45元新手大礼包</span>
                  </>
                ) : (
                  '动画新用户注册｜最高可领200元云渲染券+7天5折渲染权益'
                )}
              </BannerTitle>
              {/* <BannerSubTitle>
                {isPicture ? '' : '无门槛使用，免费体验奥斯卡级云渲染服务'}
              </BannerSubTitle> */}
            </>
          )}
          <CouponButton
            href={`${baseLink()}/sso/register`}
            backgroundColor='white'
            color='primary'
            rel='nofollow'
          >
            立即领取
          </CouponButton>
        </Wrapper>
      </BannerContainer>
    )
  }
}

export default RegisterBanner
